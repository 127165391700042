<template>
  <DialogFormWrapper>
    <template #form>
      <AddressForm
        ref="newAddressForm"
        class="pt-5"
        :editing-address="address"
        :payment-type-visible="false"
        @editAddress="updateAddress"
      />
    </template>
    <template #submit>
      <v-btn
        color="primary"
        type="submit"
        class="form-action"
        :loading="isProcessing"
        @click="$refs.newAddressForm.submitAddressForm()"
      >
        Zapisz
      </v-btn>
    </template>
  </DialogFormWrapper>
</template>

<script>
import AddressForm from './Partials/AddressForm'
import DialogFormWrapper from './Partials/DialogFormWrapper'
import { mapState, mapActions } from 'vuex'
import afterFormSubmitted from '../../mixins/afterFormSubmitted'

export default {
  components: {
    AddressForm,
    DialogFormWrapper
  },
  mixins: [afterFormSubmitted],
  computed: {
    ...mapState({
      address: state => state.layout.dialog.item,
      isProcessing: state => state.addresses.isProcessing
    })
  },
  methods: {
    ...mapActions({
      editAddress: 'addresses/editItem',
    }),
    updateAddress (address) {
      const params = {
        tableName: 'addresses',
        params: { ...address },
        id: this.address.id
      }
      this.editAddress(params)
        .then(() => {
          this.afterFormSubmitted('Zaktualizowano lokalizację')
        })
    },
  }
}
</script>
